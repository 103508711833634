import React from "react"

export default ({ imgSrc, title, description, index }) => (
  <div className="card-wrapper-100">
    {/* Profile card  - teacher cards have a 2n+2 CSS rule I want to ignore */}
    <div className="card card--shadow card--border-gradient">
      <header className="card__header">
        <div>
          <img src={imgSrc} alt="card image" />
        </div>
      </header>
      <div className="card__content">
        <div className="card__labelSticker card__labelSticker--small" />
        <div className="text-component">
          <h3 className="card__title">{title}</h3>
          {/* <span className="card__subtitle margin-bottom-md">{dateStr}</span> */}
          <p>{description}</p>
        </div>
      </div>
    </div>
  </div>
)
