import React from "react"
import { Link } from "gatsby-plugin-intl"
import GenericCard from "../good/GenericCard"

import { buildImageObj } from "./lib/helpers"
import { imageUrlFor } from "./lib/image-url"

export default ({ slug, title, excerpt, mainImage, index }) => (
  <Link
    key={slug.current}
    to={slug.current === "quiz" ? `/quiz` : `/blog/${slug.current}`}
    style={{ color: "black", textDecoration: "none" }}
  >
    <GenericCard
      index={index}
      title={title}
      description={
        excerpt
          .split(" ")
          .splice(0, 25)
          .join(" ") + "..."
      }
      imgSrc={imageUrlFor(buildImageObj(mainImage))
        .width(600)
        .height(300)
        .auto("format")
        .url()}
    />
  </Link>
)
